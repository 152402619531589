import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  chooseUsContent = [
    { image: 'assets/about/commit.png', heading: 'Commit to Work', point1: 'Our commitment to work is the cornerstone of our success.', point2: 'We believe that work is more than just a series of tasks.', point3: 'We are driven by a passion to excel in everything we do.', bgColor: '#276c93' },
    { image: 'assets/about/cost_effictive.png', heading: 'Cost Effective', point1: 'Cost-effectiveness is not just a buzzword', point2: 'We understand that in today\'s world, every penny matters', point3: 'Cost-effective solutions that don\'t compromise on quality', bgColor: '#931455' },
    { image: 'assets/about/teamwork.png', heading: 'Solid Teamwork', point1: 'We trust in each other\'s abilities, commitment, and integrity.', point2: 'Effective teamwork hinges on transparent communication', point3: 'We are committed to nurturing and strengthening our teams', bgColor: '#bd392f' },
    { image: 'assets/about/standard_exel.png', heading: 'Standard of Excellence', point1: ' We understand that every client and project is unique.', point2: 'we offer customized solutions for your needs & budget constraints', point3: 'No unnecessary frills, just what you need to succeed.', bgColor: '#f29b26' }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
