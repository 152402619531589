<div class="footer__section">
    <div class="footer_LinksSec">
        <div class="container-lg foter__Left" >
            <div class="row">
                <div class=" row text-white pt-5">
                    <!-- <div class="col-12 col-xxl-5 col-xl-6 col-md-6"><img src="../../assets/msts-logo.jpeg" alt=""
                            class="footertab_width">
                        <p class="mt-4">"TECHNICAL SERVICES FOR ALL CATEGORY AC WORK ELECTRICAL WORK PLUMBING WORK
                            PAINTING WORK TILES FIXING WORK GYPSUM CEILING WORK CARPENTRY & WOOD FLOORING WORK MASON
                            WORK DUCT CLEANING SANITATION WORK SWIMMING POOL WORK IRRIGATION WORK (GARDEN LIGHT FIXING
                            WORK)"</p>
                         <a href="" target="_blank" class="text-decoration-none" style="color: #fff;"><img
                                src="../../assets/li.png" alt="linkedin" class="p-1 border rounded"><span
                                style="position: relative; top: 4px; left: 8px;">LinkedIn</span></a> 
                    </div> -->
                    <div class="col-12 col-xl-3 col-sm-6 col-md-4 inofrM">
                        <ul class="routeLinks__footer px-0">
                            <h5 class="px-3 heading_with_border fw-bold">Technical Services</h5>
                            <li class="nav-item active"><a routerLink="" class="nav-link p-3">Ac Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3">Electrical Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3">Plumbing Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3">Painting Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3">Marble Polishing & Flooring</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3">Gypsum Ceiling Works</a></li>
                        </ul>
                    </div>
                    <div class="col-12 col-xl-3 col-sm-6 col-md-4 inofrM">
                        <ul class="routeLinks__footer px-0">
                            <h5 class="px-3 heading_with_border d-none d-md-block">&nbsp;</h5>
                            
                            <li class="nav-item"><a routerLink="" class="nav-link p-3">Carpentry & Wood Flooring </a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3"> Mason Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3"> Duct Cleaning Sanitation </a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3"> Swimming Pool Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3"> Irrigation Works</a></li>
                            <li class="nav-item"><a routerLink="" class="nav-link p-3"> Garden Light Fixing Works</a></li>
                        </ul>
                    </div>

                    


                    <div class="col-12 col-xl-3 col-sm-6 col-md-4 inofrM">
                        <ul class="routeLinks__footer px-0">
                            <h5 class="px-3 heading_with_border fw-bold">Information</h5>
                            <li class="nav-item active"><a routerLink="" class="nav-link p-3">Home</a></li>
                            <li class="nav-item"><a routerLink="/about" class="nav-link p-3">About</a>
                            </li>
                            <li class="nav-item"><a routerLink="/services" class="nav-link p-3"
                                    >Services</a></li>
                            <!-- <li  class="nav-item"><a 
                                    routerlink="/products/skedoc" href="/products/skedoc"
                                    class="nav-link p-3">Product</a></li> -->
                            <li class="nav-item"><a routerLink="/contact" class="nav-link p-3"
                                    >Contact</a></li>
                        </ul>
                    </div>
                    <div class="col-12 col-xl-3 col-sm-6 col-md-4">
                        <h5 class="heading_with_border px-3 fw-bold">Contact</h5>
                        <div class="d-flex p-3"><span><img src="../../assets/locationicon.png" alt="location"></span>
                            <p class="ms-3">P.O.Box: 283135, Dubai -UAE</p>
                        </div>
                        <div class="d-flex p-3"><span><img src="../../assets/mail.png" alt="mail"></span>
                            <p class="ms-3"><a href="multiskilluae@gmail.com" class="text-white text-decoration-none">
                                    multiskilluae@gmail.com</a></p>
                        </div>
                    </div>
                    <hr class="d-none d-md-block" >
                    <div class="text-center d-none d-md-block">
                        <p>Copyright 2023 © All rights reserved by Multi Skill Technical Services L.L.C
                        </p>
                    </div>
                </div>
                <div class="text-center d-md-none d-block"
                    style="font-size: 12px; color: #fff; padding: 24px 24px 8px;">
                    <p>Copyright 2023 © All rights reserved by Multi Skill Technical Services L.L.C </p>
                </div>
            </div>
        </div>
    </div>
</div>