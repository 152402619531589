<div class="banner-section banner">
    <h1>About Company</h1>
</div>

<div class="about-area ptb-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="img-section1">
                    <img src="../../assets/about/about-image-2.png" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="content mt-4 mt-md-0">
                    <h6>About Us</h6>
                    <p>Welcome to Multi Skill Technical Services, your trusted partner for premier services. Founded on
                        the
                        principles of integrity, excellence, and dedication, we strive to exceed expectations in every
                        interaction.</p>

                    <p>At Multi Skill Technical Services, we understand the importance of delivering top-notch services
                        tailored to
                        meet the unique needs of each client. With a team of experienced professionals, we provide a
                        wide range of services designed to enhance your experience and streamline your operations.</p>

                    <!-- <p>Customer satisfaction is at the core of everything we do. We prioritize open communication,
                        transparency, and reliability to build lasting relationships with our clients. When you choose
                        Multi Skill Technical Services, you can trust that you're partnering with a team that is
                        dedicated to your
                        success.</p> -->

                    <p>Thank you for considering Multi Skill Technical Services. We look forward to the opportunity to
                        serve you
                        and demonstrate why we're the preferred choice for premier services.</p>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="choose-us">
    <div class="container">
        <div class="row">
            <h2 class="my-5 pb-4">Why Choose Us</h2>
            <div class="col-md-6 col-lg-4 col-xl-3" style="margin-top: 8opx;" *ngFor="let content of chooseUsContent">
                <div class="position-relative">
                    <div class="img-section" [style.border-color]="content.bgColor">
                        <img [src]="content.image" alt="">
                    </div>
                    <div class="round my-5">
                        <div style="height: 60px"></div>
                        <div class="heading mb-4" [style.background-color]="content.bgColor">
                            <h5 style="color: #FFFFFF;" class="mb-0">{{content.heading}}</h5>
                        </div>
                        <ul class="list-content mb-5">
                            <li class="mb-3">
                                {{content.point1}}
                            </li>
                            <li class="mb-3">
                                {{content.point2}}
                            </li>
                            <li class="mb-3">
                                {{content.point3}}
                            </li>
                        </ul>
                    </div>
                </div>
               
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3"></div>
            <div class="col-md-6 col-lg-4 col-xl-3"></div>
            <div class="col-md-6 col-lg-4 col-xl-3"></div>
        </div>
    </div>
</div>