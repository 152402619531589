<!-- <div class="text-center mt-2">
    <img src="../../assets/coming-soon.jpg" alt="">
</div> -->


<div class="about-area py-5 mt-90">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <h2>Contact Us</h2>
                <p>We're excited to hear from you! Please don't hesitate to reach out with any questions, comments, or
                    inquiries you may have. You can contact us using the Following Ways</p>

                <div class="mt-5">
                    <div class="d-flex"><span><img src="../../assets/contact/locantion.png" alt=""></span>
                        <p class="ms-3">Street 17 - Al Quoz - Al Quoz Industrial Area 4 - Dubai - United Arab Emirates
                        </p>
                    </div>

                    <div class="d-flex"><span><img src="../../assets/contact/mail.png" alt=""></span>
                        <p class="ms-3 pt-1"><a href="mailto: multiskilluae@gmail.com" class="text-decoration-none">
                                multiskilluae@gmail.com</a></p>
                    </div>

                    <div class="d-flex"><span><img src="../../assets/contact/phone-call.png" alt=""></span>
                        <p class="ms-3 pt-1"><a href="tel:+971569277977" class="text-decoration-none">
                            +971 56 927 7977</a>, 
                            <a href="tel:+97148830418" class="text-decoration-none">
                                +97 14 883 0418</a>,
                           <br class="d-block d-sm-none"> <a href="whatsapp://send?phone=+971504403570" target="_blank"  class="text-decoration-none">
                                <img src="../../assets/whatsapp.png" width="17px" class="ms-sm-3"> +971 50 440 3570</a>
                        </p>
                    </div>

                    <div class="d-flex"><span><img src="../../assets/contact/phone-call.png" alt=""></span>
                        <p class="ms-3 pt-1">
                            Open Hours -  Mon - Sat : 8am to 5Pm</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">

                <div class="card ms-md-4 p-3 p-lg-5">
                     <form [formGroup]="contactUsForm">
                        <div class="mb-3">
                            <label for="name" class="form-label">Name</label>
                            <input type="text" class="form-control" formControlName="name" id="name">
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email address</label>
                            <input type="email" class="form-control" id="email" formControlName="email">
                        </div>
                        <div class="mb-3">
                            <label for="query" class="form-label">Enter message</label>
                            <textarea class="form-control" id="query" rows="3" formControlName="query"></textarea>
                        </div>

                        <button type="submit" class="btn btn-primary" (click)="onSubmit($event)">Submit</button>
                    </form> 

                    
                </div>

            </div>
        </div>

        <div class="mt-5">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9315.123732754731!2d55.22628320226791!3d25.115093808863072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d969d07773%3A0xf55b0fc8bb319b17!2sMulti%20Skill%20Technical%20Services%20LLC!5e0!3m2!1sen!2sin!4v1709666383638!5m2!1sen!2sin"
                width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</div>