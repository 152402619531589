import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
declare var Email: any


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactUsForm: FormGroup

  constructor(private formBuilder: FormBuilder) { }

  onSubmit(e) {
    e.preventDefault();

    // var templateParams = {
    //   to_name: 'Samy',
    //   from_name: this.contactUsForm.get('name').value,
    //   email: this.contactUsForm.get('email').value,
    //   notes: this.contactUsForm.get('query').value,
    // }
    // emailjs
    //   .send(
    //     'service_6id4rkf',
    //     'template_oqadq39',
    //     templateParams
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );

    Email.send({
      SecureToken : "54cc71e7-ca20-4428-bf7c-3ced35e62c03",
      To : 'pogularakesh209@gmail.com',
      From : "raki.sai.kiran@gmail.com",
      Subject : "This is the subject",
      Body : "And this is the body"
  }).then(
    message => alert(message)
  );    
  }

  ngOnInit(): void {
    this.contactUsForm = this.formBuilder.group({
      name : new FormControl(''),
      email : new FormControl(''),
      query : new FormControl('')
    })
  }

}
