<div class="banner-section banner">
    <h1>Our Services</h1>
</div>


<div class="about-area ptb-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-4  show filter-all filter-commercial" *ngFor="let service of servicesList">
                <div class="card service-card position-relative mb-5">
                    <div class="service-image"><img [src]="service.image" alt=""></div>

                    <div class="position-absolute icon-image" [style.border-color]="service.brColor">
                        <img [src]="service.icon" alt="">
                    </div>
                    <div class="service-body">
                        <div class="service-heading"> <h3 class="mb-3"> {{service.service}}</h3></div>
                        <ul class="list-items ms-4">
                            <li class="mb-2" *ngFor ="let option of service.lists"  >{{option.list}}</li>
                            <!-- <li class="mb-2">AC Repair</li>
                            <li class="mb-2">AC Maintainance</li> -->
                        </ul>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


