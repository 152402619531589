import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 servicesList= [
  { id: 1, service: 'AC Works', icon: 'assets/services/ac1.png' },
  { id: 2, service: 'Electrical Works', icon: 'assets/services/electrical1.png' },
  { id: 3, service: 'Plumbing Works', icon: 'assets/services/plumbing1.png' },
  { id: 4, service: 'Painting Works', icon: 'assets/services/painting1.png' },
  { id: 5, service: 'Marble Polishing & Flooring', icon: 'assets/services/tiles.png' },
  { id: 6, service: 'Gypsum Ceiling', icon: 'assets/services/ceiling.png' },
  { id: 7, service: 'carpentry', icon: 'assets/services/carpentry.png' },
  { id: 8, service: 'Mason Work', icon: 'assets/services/mason.png' },
  { id: 9, service: 'Duct Cleaning Sanitation', icon: 'assets/services/sanitation.png' },
  { id: 10, service: 'Swimming Pool', icon: 'assets/services/swimmin.png' }
  // { id: 11, service: 'Irrigation' },
]
  constructor() { }

  ngOnInit(): void {
  }

}
