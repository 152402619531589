import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  servicesList = [
    {image: 'assets/services/images/ac_service.webp', icon: 'assets/services/ac1.png', service: 'Air Conditioning Works', brColor:'#fcba03', lists: [{list: 'AC Installation'}, {list: 'AC Repair'}, {list: 'AC Maintenance'}]},
    {image: 'assets/services/images/electrician.jpg', icon: 'assets/services/electrical1.png', service: 'Electrical Works',brColor:'#036ffc', lists: [{list: 'Wiring and Rewiring'}, {list: 'Panel Upgrades'}, {list: 'Lighting Installation'}]},
    {image: 'assets/services/images/plumbing.jpg', icon: 'assets/services/plumbing1.png', service: 'Plumbing Works',brColor:'#eb07d8', lists: [{list: 'Leak Detection and Repair'}, {list: 'Drain Cleaning'}, {list: 'Pipe Repair and Replacement'}]},
    {image: 'assets/services/images/painting.jpeg', icon: 'assets/services/painting1.png', service: 'Painting Works',brColor:'#18c718', lists: [{list: 'Interior Painting'}, {list: 'Exterior Painting'}, {list: 'Commercial Painting'}]},
    {image: 'assets/services/images/tiles-fixing.jpg', icon: 'assets/services/tiles.png', service: 'Marble Polshing & Flooring',brColor:'#003366', lists: [{list: 'Tile Installation'}, {list: 'Grout Cleaning and Sealing'}, {list: 'Tile Repair'}]},
    {image: 'assets/services/images/gypsum.jpg', icon: 'assets/services/ceiling.png', service: 'Gypsum Ceiling',brColor:'#990000', lists: [{list: 'Installation'}, {list: 'Lighting Integration'}, {list: 'Repair and Maintenance'}]},
    {image: 'assets/services/images/carpentry.jpeg', icon: 'assets/services/carpentry.png', service: 'Carpentry Works',brColor:'#3333cc', lists: [{list: 'Trim and Molding'}, {list: 'Wood Flooring'}, {list: 'Custom Furniture'}]},
    {image: 'assets/services/images/masonry-work-services.jpg', icon: 'assets/services/mason.png', service: 'Mason Works',brColor:'#993399', lists: [{list: 'Brickwork'}, {list: 'Stonework'}, {list: 'Concrete Work'}]},
    {image: 'assets/services/images/duct-sanitation.png', icon: 'assets/services/sanitation.png', service: 'Duct Cleaning Sanitation',brColor:'#339966', lists: [{list: 'Thorough Inspection'}, {list: 'Deep Cleaning'}, {list: 'Sanitization'}]},
    {image: 'assets/services/images/swimming-pool.webp', icon: 'assets/services/swimmin.png', service: 'Swimming Pool Works',brColor:'#000066', lists: [{list: 'Professional Installation'}, {list: 'Maintenance & Repairs'}, {list: 'Custom Design'}]},
    {image: 'assets/services/images/irrigation-service.avif', icon: 'assets/services/irrigation.webp', service: 'Irrigation Works',brColor:'#cc9900', lists: [{list: 'Expert Installation'}, {list: 'Seasonal Maintenance'}, {list: 'Smart Technology Integration'}]},
    {image: 'assets/services/images/garden.jpg', icon: 'assets/services/garden.jpg', service: 'Garden light Fixing',brColor:'#993366', lists: [{list: 'Professional Installation'}, {list: 'Energy-Efficient Solutions'}, {list: 'Maintenance and Repair'}]}
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
