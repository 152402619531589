<!-- <div id="carouselExampleCaptions" class="carousel slide  carousel-fade" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
            aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
            aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="../../assets/banners/electric1.jpg" height="600px" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <h5>First slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../assets/banners/plumbing.jpg" height="600px" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <div class="main-banner-content aos-init aos-animate" data-aos="fade-right" data-aos-delay="50"
                    data-aos-duration="500">
                    <span>Welcome to Multi Skill Technical Services</span>
                    <h1>Bringing comfort back with our repair expertise</h1>
                    <p>We're supported by a vibrant team of young service specialists committed to repairs.</p>

                    <div class="banner-btn">
                        <a routerLink="/contact" class="default-btn">Appointment now</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../assets/banners/carpentry.jpg" height="600px" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <h5>Third slide label</h5>
                <p>Some representative placeholder content for the third slide.</p>
            </div>
        </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div> -->


<div class="main-banner-area">
    <div class="container">
        <div class="main-banner-content aos-init aos-animate" data-aos="fade-right" data-aos-delay="50"
            data-aos-duration="500">
            <a class="navbar-brand" href="#">
                <img src="../../assets/msts-logo.jpeg" class="mb-5" alt="">
              </a>
            <h1>Welcome to <br>Multi Skill Technical Services</h1>
            <div class="d-sm-flex">
                <div class="content">
                    <h4 class="text-white">Bringing comfort back with our repair expertise</h4>
                    <p>We're supported by a vibrant team of young service specialists committed to repairs.</p>
                </div>
                <div class="mx-auto h4">
                    <a href="whatsapp://send?phone=+971504403570" target="_blank"  class="text-decoration-none">
                        <img src="../../assets/whatsapp.png" width="17px" class="ms-sm-3"> +971 50 440 3570</a>
                </div>
            </div>

            <div class="banner-btn">
                <a routerLink="/contact" class="default-btn">Appointment now</a>
            </div>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-wrap">
                    <img src="../../assets/banners/43040.jpg" width="100%" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About company</span>
                    <h3>We specialize in the seamless installation and ongoing maintenance of a variety of equipment.
                    </h3>
                    <p class="mb-0">At Multi Skill Technical Services, we pride ourselves on being a leading provider of
                        sophisticated installation and maintenance services. With years of experience and a commitment
                        to excellence, we have established ourselves as a trusted partner for businesses and homeowners.
                    </p>

                    <ul class="about-list list-style-type">
                        <li> Highly skilled &amp; experienced engineers</li>
                        <!-- <li> Work guaranteed for 2 year</li> -->
                        <li> Emergency call outs 24/7</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="about" class="default-btn">Learn more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="d-flex home-page">
    <div class="col-md-6 content">
        <ul>
            <li> <span></span>WELCOME TO OUR COMPANY </li>
        </ul>
        <div class="heading">
            <h1><span> MULTI SKILL </span> TECHNICAL SERVICES L.L.C </h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book</p>
            <div class="d-flex">
                <button class="btn btn-primary readmore">Read more</button>
                <button class="btn btn-outline-primary ms-3">Watch Video</button>
            </div>
        </div>
    </div>

    <div class="col-md-6 banner-image">
        <img src="../../assets/air-conditioner-mounted-white-wall.jpg" alt="">
    </div>

</div> -->

<section class="service-sec1 background-image" id="service-sec">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-7 col-md-8">
                <div class="title-area text-center"><span class="sub-title"><span class="text">Our
                            services</span></span>
                    <h2 class="sec-title">Services We Provide</h2>
                    <p class="sec-text">Website and mobile sit amet, consectetur adipiscing elit. Morbi obortis ligula
                        euismod sededesty am augue nisl.</p>
                </div>
            </div>
        </div>


        <div>
            <ul class="services-list text-center">
                <li *ngFor="let service of servicesList"><img class="mb-3" width="59px" height="54px"
                        [src]="service.icon" [alt]="service.service"><br>
                    {{service.service}}</li>
                <!-- <li><a href="service_detail.html"><img src="images/services-icons/electrical.png" alt="electrical"><br>
              Electrical</a></li>
            <li><a href="#"><img src="images/services-icons/plumbing.png" alt="plumbing"><br>
              Plumbing</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/appliances.png" alt="appliances"><br>
              Appliances</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/carpentry.png" alt="carpentry"><br>
              Carpentry</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/geyser.png" alt="geyser"><br>
              Geyser Service</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/vehicle.png" alt="vehicle"><br>
              Vehicle Care</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/pest.png" alt="pest"><br>
              Pest Control</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/painting.png" alt="painting"><br>
              Painting</a></li>
            <li><a href="service_detail.html"><img src="images/services-icons/more.png" alt="more"><br>
              View More</a></li> -->
            </ul>
        </div>


        <!-- <div class="row gy-4 justify-content-center">
            <div class="col-xl-3 col-md-6">
                <div class="service-card">
                    <div class="box-icon"><img src="assets/service_card_1.svg" alt="Icon"></div>
                    <h3 class="box-title"><a href="service-details.html">Air Conditioning</a></h3>
                    <p class="box-text">Risus turpis id mauris tellus ultricies cras nulla.</p><a
                        href="service-details.html" class="box-btn"><i class="fas fa-arrow-up-right"></i></a>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="service-card">
                    <div class="box-icon"><img src="assets/service_card_1.svg" alt="Icon"></div>
                    <h3 class="box-title"><a href="service-details.html">Ventilation</a></h3>
                    <p class="box-text">Developing a comprehense IT strategy that aligns.</p><a
                        href="service-details.html" class="box-btn"><i class="fas fa-arrow-up-right"></i></a>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="service-card">
                    <div class="box-icon"><img src="assets/service_card_1.svg" alt="Icon"></div>
                    <h3 class="box-title"><a href="service-details.html">Filtration System</a></h3>
                    <p class="box-text">Website and mobile apps design are critical.</p><a href="service-details.html"
                        class="box-btn"><i class="fas fa-arrow-up-right"></i></a>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="service-card">
                    <div class="box-icon"><img src="assets/service_card_1.svg" alt="Icon"></div>
                    <h3 class="box-title"><a href="service-details.html">Electronical</a></h3>
                    <p class="box-text">Encryption is a method of converting sensitive.</p><a
                        href="service-details.html" class="box-btn"><i class="fas fa-arrow-up-right"></i></a>
                </div>
            </div>
        </div> -->
    </div>
</section>


<section class="bg-smoke overflow-hidden bg-image" id="process-sec"
    style="background-image: url(../../assets/how-it-works/banner.jpg);">
    <div class="container pb-5">
        <div class="title-area text-center pt-5">
            <h2 class="sec-title">Our Standard Working Process</h2>
        </div>
        <div class="process-card-area">
            <div class="row gx-0 gy-40 justify-content-center">
                <div class="col-md-6 col-xl-4 process-card-wrap">
                    <div class="process-card">
                        <div class="process-card_img"><img src="../../assets/how-it-works/process_card_1.jpg" alt="Image">
                        </div>
                        <h2 class="box-title">Online Booking</h2>
                        <p class="process-card_text">Online booking typically involves selecting a date and time,
                            choosing from available options, providing personal.</p>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 process-card-wrap">
                    <div class="process-card">
                        <div class="process-card_img"><img src="../../assets/how-it-works/process_card_2.jpg" alt="Image">
                        </div>
                        <h2 class="box-title">Inspect &amp; Analyze</h2>
                        <p class="process-card_text">Inspect and analyze refers to the process of examining something
                            closely in order to gain a better understanding.</p>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 process-card-wrap">
                    <div class="process-card">
                        <div class="process-card_img"><img src="../../assets/how-it-works/process_card_3.jpg" alt="Image">
                        </div>
                        <h2 class="box-title">Solving Problem</h2>
                        <p class="process-card_text">Solving a problem refers to the process of finding a solution to a
                            particular issue or challenge that is preventing outcome.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="space background-image1 mt-5">
    <div class="container">
        <div class="row gy-30 align-items-center justify-content-center justify-content-md-between">
            <div class="col-md-auto">
                <div class="title-area mb-0 text-center text-md-start"><span class="sub-title text-white">We are here to
                        answer your questions 24/7</span>
                    <h2 class="sec-title text-white">Need A Consultation?</h2>
                </div>
            </div>
            <div class="col-md-auto text-center"><a href="shop-details.html" class="ot-btn style3">Let’s Get Started</a>
            </div>
        </div>
    </div>
</section> -->